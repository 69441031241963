/* @keyframes lds-pacman-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes lds-pacman-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes lds-pacman-2 {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-webkit-keyframes lds-pacman-2 {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes lds-pacman-3 {
  0% {
    -webkit-transform: translate(190px, 0);
    transform: translate(190px, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(70px, 0);
    transform: translate(70px, 0);
    opacity: 1;
  }
}
@-webkit-keyframes lds-pacman-3 {
  0% {
    -webkit-transform: translate(190px, 0);
    transform: translate(190px, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(70px, 0);
    transform: translate(70px, 0);
    opacity: 1;
  }
}
.lds-pacman {
  position: relative;
}
.lds-pacman > div:nth-child(2) div {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 120px;
  height: 60px;
  border-radius: 120px 120px 0 0;
  background: #fcb711;
  -webkit-animation: lds-pacman-1 1s linear infinite;
  animation: lds-pacman-1 1s linear infinite;
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}
.lds-pacman > div:nth-child(2) div:nth-child(2) {
  -webkit-animation: lds-pacman-2 1s linear infinite;
  animation: lds-pacman-2 1s linear infinite;
}
.lds-pacman > div:nth-child(1) div {
  position: absolute;
  top: 92px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #f37021;
  -webkit-animation: lds-pacman-3 1s linear infinite;
  animation: lds-pacman-3 1s linear infinite;
}
.lds-pacman > div:nth-child(1) div:nth-child(1) {
  -webkit-animation-delay: -0.67s;
  animation-delay: -0.67s;
}
.lds-pacman > div:nth-child(1) div:nth-child(2) {
  -webkit-animation-delay: -0.33s;
  animation-delay: -0.33s;
}
.lds-pacman > div:nth-child(1) div:nth-child(3) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.lds-pacman {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
} */

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.lds-spinner {
  position: relative;
}
.lds-spinner div {
  left: 82px;
  top: 45px;
  position: absolute;
  -webkit-animation: lds-spinner linear 0.9s infinite;
  animation: lds-spinner linear 0.9s infinite;
  background: #f9c647;
  width: 36px;
  height: 30px;
  border-radius: 20%;
  -webkit-transform-origin: 18px 55px;
  transform-origin: 18px 55px;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.7875s;
  animation-delay: -0.7875s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation-delay: -0.675s;
  animation-delay: -0.675s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.5625s;
  animation-delay: -0.5625s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.3375s;
  animation-delay: -0.3375s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-animation-delay: -0.225s;
  animation-delay: -0.225s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.1125s;
  animation-delay: -0.1125s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.lds-spinner {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
